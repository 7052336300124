<template>
  <div id="QuestionForm">
    <div class="title-exam">
      <svg-icon iconClass="project" size="30" style="margin-right:6px" />{{form.title}}
    </div>
    <van-cell-group :border="true">
      <van-cell size="large" v-for="(item,index) in form.examinationBanks" :key="index" :border="true">
        <template #title>
          <span>{{(index+1)+". "+item.issueTitle}}</span>
          <van-tag type="success" round style="margin-left:10px;padding: 3px 10px;">{{ codeConvertLabel(item.issueType, dict.question_type)}}</van-tag>
        </template>
        <template #label>
          <div class="exame-radio" v-if="item.issueType === 1">
            <van-radio-group v-model="item.issueCheckId">
              <van-radio class="radio-item" :name="itm2.id" label-disabled v-for="(itm2,index1) in item.examinationContents" :key="index1">{{(EnNumber[index1])+'. '+itm2.option}}</van-radio>
            </van-radio-group>
          </div>
          <div class="exame-check" v-if="item.issueType === 2">
            <van-checkbox readonly v-model="itm3.checkId" label-disabled class="checkbox-item" shape="square" :name="itm3.id" v-for="(itm3,index3) in item.examinationContents" :key="index3">{{(EnNumber[index3])+'. '+itm3.option}}</van-checkbox>
          </div>
          <div class="exame-input" v-if="item.issueType === 3">
            <van-field v-model="item.issueAnswer" :border="true" readonly rows="3" autosize type="textarea" placeholder="请填写" :required="true" :rules="[{ required: true  } ]" />
          </div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import examination from '@/api/modules/question/examination'
import { report, findById } from '@/api/modules/question/invTaskStu'
import { codeConvertLabel } from "@/utils";
import { addBatch } from '@/api/modules/question/invTaskStuScore'
import { getAllByZbIdAndTaskId } from '@/api/modules/question/invTaskStuScore'

export default {
  name: "QuestionDeatil",
  dicts: ['question_type'],
  data() {
    return {
      form: {
        id: null,
        title: null,
        examinationBanks: [],
      },
      EnNumber: {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
        4: 'E',
        5: 'F',
        6: 'G',
        7: 'H',
        8: 'I',
        9: 'J',
        10: 'K',
        11: 'L',
        12: 'M',
        13: 'N',
        14: 'O',
        15: 'P',
        16: 'Q',
      },
      loading: false,
    }
  },
  async created() {
    let result = []
    await getAllByZbIdAndTaskId({
      zbid: this.$route.query.zbid,
      examPaperId: this.$route.query.examinationId,
    }).then((res) => {
      result = res
    })
    await examination.findById(this.$route.query.examinationId).then((res) => {
      let data = res.examinationBanks.map((source) => {
        if (result.length > 0) {
          // 处理数据
          result.forEach((item) => {
            if (item.subjectId === source.subjectId) {
              if (item.issueType === 1) {
                source['issueCheckId'] = item.issueCheckId
              } else if (item.issueType === 2) {
                let ids = JSON.parse(item.checkBoxIds)
                source['issueCheckId'] = ids
                let examinations = source.examinationContents
                examinations.forEach((item3) => {
                  if (ids.indexOf(item3.id) > -1) {
                    item3['checkId'] = true
                  } else {
                    item3['checkId'] = false
                  }
                })
                source['examinationContents'] = examinations
              } else if (item.issueType === 3) {
                source['issueAnswer'] = item.issueAnswer
              }
            }
          })
        }
        return source
      })
      this.form.title = res.title
      this.form.examinationBanks = data
    })
  },
  methods: {
    codeConvertLabel,

    getLabel(dict, value) {
      return dict
        .filter((item) => {
          return item.value === value
        })
        .map((item) => {
          return item.label
        })
        .toString()
    },
    cencel() {
      this.form = {}
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
#QuestionForm {
  .title-exam {
    width: 100%;
    background-color: #ffffff;
    display: inline-flex;
    justify-content: left;
    align-items: center;
    padding: 15px 0;
  }

  .radio-item {
    margin: 10px 15px;
  }

  .checkbox-item {
    margin: 10px 15px;
  }
}
</style>